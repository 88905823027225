module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Oppa888","short_name":"Oppa888","start_url":"/","background_color":"#1A1D22","theme_color":"#ffe84d","display":"standalone","icon":"src/oppa-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"da9bb826969ebe7737302b13d668bc56"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-140145365-6"],"pluginConfig":{"head":false,"respectDNT":false,"exclude":["/preview/**","/zopim/"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
